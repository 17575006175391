import { TablePagination } from '@mui/material';
import React, { useState } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import HistoryOneEdit from './HistoryOneEdit';
import { DELETE } from '../../helpers/httpHelper';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';

function HistoryTable({ history, decoded, updateDataSet }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const tableHeader = [
		'Recovery Officer',
		'Bill Cycle Code',
		'Month Due',
		'Credit Limit',
		'Status Date',
		'Outstanding Amount',
		'Extra Amount',
		'Last Payment Date',
		'Last Payment Amount',
		'Payment Details',
		'Recovery Office Comments',
		'Other Comments',
	];
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [oneHistory, setOneHistory] = useState(null);

	const handlePopup = () => {
		setIsPopupOpen(!isPopupOpen);
	};

	const handleEdit = (history) => {
		setOneHistory(history);
		handlePopup();
	};

	const handleDelete = async (historyId) => {
		try {
			const res = await DELETE(`customer/delete-one/${historyId}`, decoded.token);
			updateDataSet();
			console.log(res);
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Delete this record?',
			message: 'It will be Delete permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={handleDelete}
				/>
			),
		});
	};
	return (
		<>
			<div>
				{isPopupOpen && (
					<div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 bg-gray-900">
						<div className="bg-white p-8 rounded-lg relative w-[85%]  md:w-[65%]">
							<button
								className="absolute top-0 right-0 mt-3 mr-3 bg-gray-200 rounded-full p-1 hover:bg-gray-300 focus:outline-none "
								onClick={handlePopup}
							>
								<CloseIcon />
							</button>
							<h2 className="text-lg font-medium mb-4">Edit History</h2>
							<div className="mb-4">
								<HistoryOneEdit
									history={oneHistory}
									decoded={decoded}
									handlePopup={handlePopup}
									updateDataSet={updateDataSet}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className="grid grid-cols-1  gap-6 shadow-xl border rounded p-3">
				<div className="md:col-span-2">
					<p className="mt-1 text-xl font-bold text-gray-600 text-center">History</p>
				</div>
				<div className="block overflow-auto">
					<table className="min-w-full divide-y divide-gray-200">
						<thead className="bg-gray-50">
							<tr>
								{decoded?.userType?.name === 'SYSTEM' && <th>Edit</th>}
								{tableHeader.map((header, index) => (
									<th
										key={index}
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
									>
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white divide-y divide-gray-200">
							{history?.length > 0 ? (
								history
									?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((history, index) => (
										<tr key={index}>
											{decoded?.userType?.name === 'SYSTEM' && (
												<td>
													<div className="flex gap-3">
														<button
															className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 focus:outline-none"
															onClick={() => handleEdit(history)}
														>
															<DriveFileRenameOutlineIcon />
														</button>
														<button
															className="bg-gray-200 rounded-full p-1 hover:bg-gray-300 focus:outline-none"
															onClick={() => submit(history?._id)}
														>
															<DeleteIcon />
														</button>
													</div>
												</td>
											)}
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.recoveryOfficer ? history?.recoveryOfficer : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.billCycleCode ? history?.billCycleCode : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.monthDue ? history?.monthDue : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.creditLimit ? history?.creditLimit : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.statusDate
														? new Date(history?.statusDate)?.toLocaleDateString('en-US', {
																weekday: 'long',
																year: 'numeric',
																month: 'short',
																day: 'numeric',
														  })
														: '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.outstandingAmount ? history?.outstandingAmount : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.extraAmount ? history?.extraAmount : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.lastPaymentDate
														? new Date(history?.lastPaymentDate)?.toLocaleDateString(
																'en-US',
																{
																	weekday: 'long',
																	year: 'numeric',
																	month: 'short',
																	day: 'numeric',
																}
														  )
														: '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.lastPaymentAmount ? history?.lastPaymentAmount : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.paymentDetails ? history?.paymentDetails : '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.recoveryOfficeComments
														? history?.recoveryOfficeComments
														: '-'}
												</div>
											</td>
											<td className="px-6 py-4 whitespace-nowrap">
												<div className="text-sm text-gray-900">
													{history?.otherComments ? history?.otherComments : '-'}
												</div>
											</td>
										</tr>
									))
							) : (
								<tr>
									<td className="px-6 py-4 whitespace-nowrap" colSpan="12">
										<Alert severity="error">No History found</Alert>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<TablePagination
						count={history?.length}
						rowsPerPage={rowsPerPage}
						page={page}
						component="div"
						SelectProps={{
							inputProps: {
								'aria-label': 'rows per page',
							},
							native: true,
						}}
						rowsPerPageOptions={[10, 25, 50, 100]}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			</div>
		</>
	);
}

export default HistoryTable;
