import React, { useEffect, useState } from 'react';
import { POST, PUT } from '../../helpers/httpHelper';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';
import { Alert } from '@mui/material';

function AddBank({ bank, setBank, getAllBanks, decoded }) {
	const [bankName, setBankName] = useState('');

	const [bankNameAlert, setBankNameAlert] = useState(false);

	useEffect(() => {
		if (bank?._id) {
			setBankName(bank?.name);
		} else {
			setBankName('');
		}
	}, [bank]);

	const createBank = async () => {
		try {
			if (bankName === '') {
				setBankNameAlert(true);
				return;
			}
			const response = await POST('bank/create', { name: bankName.toUpperCase() });
			setBank({});
			getAllBanks();
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		if (bankName === '') {
			setBankNameAlert(true);
			return;
		}
		confirmAlert({
			title: 'Are you sure you want to Create this Bank?',
			message: 'It will be Create permanently.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={createBank}
				/>
			),
		});
	};

	useEffect(() => {
		if (bank) {
			setBankNameAlert(false);
		}
	}, [bankName]);

	const updateBank = async () => {
		try {
			const response = await PUT(`bank/update?id=${bank?._id}`, { name: bankName });
			setBank({});
			getAllBanks();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			{decoded?.isAdmin && (
				<div className="flex flex-col gap-6">
					<div className="flex bg-slate-200 p-2 rounded items-center">
						<label htmlFor="bank" className="w-44 ml-2 text-sm font-medium text-black">
							Bank Name
						</label>
						<input
							required
							type="text"
							className="w-full p-2 rounded"
							id="bank"
							placeholder="Enter Bank Name"
							value={bankName.toUpperCase()}
							onChange={(e) => setBankName(e.target.value)}
						/>
					</div>
					{bankNameAlert && <Alert severity="error">Bank Name is Required</Alert>}

					{!bank?._id ? (
						<button
							type="submit"
							className="btn btn-primary bg-green-300 rounded leading-10 hover:bg-green-400"
							onClick={() => submit()}
						>
							Create Bank
						</button>
					) : (
						<div className="w-full flex flex-col">
							<button
								type="submit"
								className="btn btn-primary bg-gray-300 rounded leading-10 hover:bg-gray-400"
								onClick={() => updateBank()}
							>
								Update Bank
							</button>
							<div
								className="text-center text-blue-700 cursor-pointer underline-offset-1"
								onClick={() => setBank({})}
							>
								Create New Bank
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}

export default AddBank;
