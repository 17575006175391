import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';
import { PUT } from '../../helpers/httpHelper';

const HistoryOneEdit = ({ history, handlePopup, updateDataSet, decoded }) => {
	const [recoveryOfficer, setRecoveryOfficer] = useState(history.recoveryOfficer);
	const [billCycleCode, setBillCycleCode] = useState(history.billCycleCode);
	const [monthDue, setMonthDue] = useState(history.monthDue);
	const [creditLimit, setCreditLimit] = useState(history.creditLimit);
	const [statusDate, setStatusDate] = useState(history.statusDate);
	const [outstandingAmount, setOutstandingAmount] = useState(history.outstandingAmount);
	const [extraAmount, setExtraAmount] = useState(history.extraAmount);
	const [lastPaymentDate, setLastPaymentDate] = useState(history.lastPaymentDate);
	const [lastPaymentAmount, setLastPaymentAmount] = useState(history.lastPaymentAmount);
	const [paymentDetails, setPaymentDetails] = useState(history.paymentDetails);
	const [recoveryOfficeComments, setRecoveryOfficeComments] = useState(history.recoveryOfficeComments);
	const [otherComments, setOtherComments] = useState(history.otherComments);

	const handleSubmit = async () => {
		try {
			const updatedData = {
				recoveryOfficer,
				billCycleCode,
				monthDue,
				creditLimit,
				statusDate,
				outstandingAmount,
				extraAmount,
				lastPaymentDate,
				lastPaymentAmount,
				paymentDetails,
				recoveryOfficeComments,
				otherComments,
			};
			handlePopup();
			const res = await PUT(`customer/edit-one/${history._id}`, updatedData, decoded.token);
			updateDataSet();
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Update this record?',
			message: 'It will be Update permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={handleSubmit}
				/>
			),
		});
	};

	return (
		<div>
			<div className="h-[350px] overflow-auto p-5">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
					<label className="flex flex-col whitespace-nowrap">
						<span>Recovery Officer:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={recoveryOfficer ? recoveryOfficer : ''}
							onChange={(e) => setRecoveryOfficer(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Bill Cycle Code:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={billCycleCode ? billCycleCode : ''}
							onChange={(e) => setBillCycleCode(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Month Due:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={monthDue ? monthDue : ''}
							onChange={(e) => setMonthDue(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Credit Limit:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={creditLimit ? creditLimit : ''}
							onChange={(e) => setCreditLimit(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Status Date:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="date"
							value={statusDate ? statusDate.slice(0, 10) : 'N/A'}
							onChange={(e) => setStatusDate(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Outstanding Amount:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={outstandingAmount ? outstandingAmount : ''}
							onChange={(e) => setOutstandingAmount(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Extra Amount:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={extraAmount ? extraAmount : ''}
							onChange={(e) => setExtraAmount(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Last Payment Date:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="date"
							value={lastPaymentDate ? lastPaymentDate.slice(0, 10) : 'N/A'}
							onChange={(e) => setLastPaymentDate(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Last Payment Amount:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={lastPaymentAmount ? lastPaymentAmount : ''}
							onChange={(e) => setLastPaymentAmount(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Payment Details:</span>
						<input
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
							type="text"
							value={paymentDetails ? paymentDetails : ''}
							onChange={(e) => setPaymentDetails(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Recovery Office Comments:</span>
						<textarea
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-20"
							type="text"
							value={recoveryOfficeComments ? recoveryOfficeComments : ''}
							onChange={(e) => setRecoveryOfficeComments(e.target.value)}
						/>
					</label>

					<label className="flex flex-col whitespace-nowrap">
						<span>Other Comments:</span>
						<textarea
							className="pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm h-20"
							type="text"
							value={otherComments ? otherComments : ''}
							onChange={(e) => setOtherComments(e.target.value)}
						/>
					</label>
				</div>
			</div>
			<div className="pl-5 flex gap-2">
				<button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={submit}>
					Submit
				</button>
				<button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={handlePopup}>
					Cancel
				</button>
			</div>
		</div>
	);
};

export default HistoryOneEdit;
