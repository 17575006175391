import * as React from 'react';
import axios from 'axios';
import { useState } from 'react';
import logo from '../../asset/image/logo.png';
import login from '../../asset/image/login.png';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignInSide = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordType, setPasswordType] = useState('password');

	const loginSuccess = () =>
		toast.success('Login Success', {
			position: 'top-right',
			autoClose: 1950,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});

	const loginFail = (msg) =>
		toast.error(`${msg}`, {
			position: 'top-right',
			autoClose: 1950,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const loginWait = (msg) =>
		toast.warning(`${msg}`, {
			position: 'top-right',
			autoClose: 1950,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});

	const loginHandler = async () => {
		try {
			loginWait('Please wait...');
			const response = await axios.post(`${process.env.REACT_APP_API_LINK_PREFIX}user/login`, {
				email,
				password,
			});
			localStorage.setItem('token', response.data.tokens);
			loginSuccess();
			setTimeout(() => {
				window.location.href = '/';
			}, 2000);
		} catch (error) {
			console.log(error);
			loginFail(error.response.data.message);
		}
	};

	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	return (
		<div className="md:grid md:grid-cols-3">
			<div className="md:col-span-2 hidden md:block self-center">
				<div className=" flex items-center justify-center ">
					<img className="w-3/5" src={logo} alt="logo" />
				</div>
			</div>
			<div className="flex flex-col gap-3 border-l-2 shadow-2xl border-t-gray-600 p-6 lg:p-16 h-screen items-center justify-center">
				<div className=" flex items-center justify-center ">
					<img className="w-1/4" src={login} alt="logo" />
				</div>
				<input
					className="bg-gray-300 rounded p-2  w-full hover:bg-slate-200"
					placeholder="Email Address"
					type="text"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<div className="flex w-full">
					<input
						type={passwordType}
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						name="password"
						className="bg-gray-300 rounded rounded-tr-none rounded-br-none p-2 w-full hover:bg-slate-200"
						placeholder="Password"
					/>

					{passwordType === 'password' ? (
						<button
							className="bg-gray-300 rounded rounded-tl-none rounded-bl-none p-2  hover:bg-slate-200"
							onClick={togglePassword}
						>
							<VisibilityIcon />
						</button>
					) : (
						<button
							className="bg-gray-500 rounded rounded-tl-none rounded-bl-none p-2  hover:bg-slate-600"
							onClick={togglePassword}
						>
							<VisibilityOffIcon />
						</button>
					)}
				</div>

				<button
					className="bg-blue-500 rounded w-1/2 p-2  hover:bg-blue-600 text-white font-bold"
					onClick={loginHandler}
				>
					Login
				</button>
			</div>
		</div>
	);
};

export default SignInSide;
