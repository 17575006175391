import React, { useEffect, useRef, useState } from 'react';
import { ExcelRenderer } from 'react-excel-renderer';
import { GET, POST } from '../../helpers/httpHelper';
import { Alert, Autocomplete, TextField } from '@mui/material';

function BulkUpload({ setOpen, uploadSuccess, uploadFail }) {
	const [state, setState] = useState({});
	const [bank, setBank] = useState([]);
	const [selectedBank, setSelectedBank] = useState(null);

	//! Alert
	const [bankAlert, setBankAlert] = useState(false);
	const [fileAlert, setFileAlert] = useState(false);
	const [isValidFile, setIsValidFile] = useState(true);

	const fileHandler = (event) => {
		const fileObj = event.target.files[0];

		// Check if selected file is a Blob object
		if (fileObj instanceof Blob) {
			ExcelRenderer(fileObj, (err, resp) => {
				if (err) {
					console.log(err);
				} else {
					setState(resp.rows);
					console.log(resp);
				}
			});
		}
	};

	//! Get All Bankes
	const getBank = async () => {
		try {
			const response = await GET('bank/get');
			response.data.sort((a, b) => {
				const nameA = a.name?.toUpperCase();
				const nameB = b.name?.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			const index = response.data.findIndex((x) => x.name === 'SYSTEM');
			response.data.splice(index, 1);
			setBank(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	// create dummy headers
	const dummyHeader = [
		'Card Holder Name',
		'Address',
		'Telephone Number ',
		'LOD Date',
		'Visit Date',
		'Visit Details',
		'Settlement Plane Details',
		'NIC',
		'Relative Name',
		'Relative Telephone',
		'Relationship',
		'Recovery Officer',
		'Bill Cycle Code',
		'Month Due',
		'Credit Limit',
		'Status Date',
		'Outstanding Amount',
		'Extra Amount',
		'Last Payment Date',
		'Last Payment Amount',
		'Payment Details',
		'Recovery Office Comments',
		'Other Comments',
	];

	//! Update Data Set to DB
	const updateDataSet = async () => {
		try {
			setIsValidFile(true);

			if (!selectedBank || !state.length) {
				if (!selectedBank) setBankAlert(true);
				if (!state.length) setFileAlert(true);
				return;
			}
			for (let i = 0; i < dummyHeader.length; i++) {
				if (state[0][i] !== dummyHeader[i]) {
					setIsValidFile(false);
					return;
				}
			}
			if (!isValidFile) return;
			const arr = [];
			for (let i = 0; i < state.length; i++) {
				if (i === 0) continue;
				let ob = {
					nic: state[i][7],
					name: state[i][0],
					address: state[i][1],
					tel: state[i][2],
					lodDate: state[i][3],
					visitDate: state[i][4],
					visitDetails: state[i][5],
					settlement: state[i][6],
					relativeName: state[i][8],
					relativeTel: state[i][9],
					relativeRelation: state[i][10],
					recoveryOfficer: state[i][11],
					billCycleCode: state[i][12],
					monthDue: state[i][13],
					creditLimit: state[i][14],
					statusDate: state[i][15],
					outstandingAmount: state[i][16],
					extraAmount: state[i][17],
					lastPaymentDate: state[i][18],
					lastPaymentAmount: state[i][19],
					paymentDetails: state[i][20],
					recoveryOfficeComments: state[i][21],
					otherComments: state[i][22],
				};
				arr.push(ob);
			}

			setOpen(false);

			const response = await POST('customer/create', {
				data: arr,
				bank: selectedBank?._id,
			});

			console.log(response);

			if (response.status === 200) {
				uploadSuccess();
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			}
			if (response.status === 500) {
				uploadFail();
			}
		} catch (err) {
			uploadFail();
			console.log(err);
		}
	};

	useEffect(() => {
		getBank();
	}, []);

	useEffect(() => {}, [state]);

	//! File Upload Handler using input type file and ref
	const fileInputRef = useRef(null);
	const [fileName, setFileName] = useState('');

	const handleFileUpload = (e) => {
		if (!e.target?.files.length) {
			return setFileName(''), setState({});
		}
		// get the uploaded file and its name
		const file = e.target?.files[0];
		const name = file.name;
		fileHandler(e);
		// set the file name in state
		setFileName(name || '');
	};

	const handleClick = () => {
		// trigger the input field to open the file dialog
		fileInputRef.current.click();
	};
	//! end of file upload handler  using input type file and ref

	//! Alert Handler for Bank and File Upload Alert Message
	useEffect(() => {
		if (selectedBank) {
			setBankAlert(false);
		}
		if (state.length) {
			setFileAlert(false);
		}
	}, [selectedBank, state]);

	return (
		<div className="flex flex-col gap-6">
			<Autocomplete
				id="bank-select"
				options={bank}
				getOptionLabel={(option) => option.name}
				renderInput={(params) => <TextField {...params} label="Select Bank" variant="outlined" />}
				onChange={(event, value) => setSelectedBank(value)}
				value={selectedBank || null}
			/>
			{bankAlert && <Alert severity="error">Please select a bank</Alert>}
			<input
				multiple={false}
				type="file"
				ref={fileInputRef}
				className="bg-gray-500 rounded  p-2  hover:bg-slate-600"
				accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx"
				onChange={handleFileUpload}
				style={{ display: 'none' }} // hide the input field
			/>
			<div className="flex w-full h-14">
				{fileName ? (
					<p className="bg-gray-300  rounded rounded-tr-none rounded-br-none w-full p-2  flex items-center">
						{fileName}
					</p>
				) : (
					<p className="bg-gray-300  rounded rounded-tr-none rounded-br-none w-full p-2  flex items-center">
						No file selected
					</p>
				)}

				<button
					className="bg-gray-300 rounded rounded-tl-none rounded-bl-none p-2  hover:bg-slate-200 flex items-center w-32"
					onClick={handleClick}
				>
					Upload File
				</button>
			</div>
			{fileAlert && <Alert severity="error">Please select a file to upload</Alert>}
			{isValidFile ? null : <Alert severity="error">File is not valid</Alert>}
			<div>
				<button
					// className="bg-red-600 rounded hover:bg-red-700 cursor-pointer text-white font-bold float-right leading-10 px-10"
					className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right p-2 "
					autoFocus
					onClick={updateDataSet}
				>
					Upload and Close
				</button>
			</div>
		</div>
	);
}

export default BulkUpload;
