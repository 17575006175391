import React, { useEffect, useState } from 'react';
import { GET, POST, PUT } from '../../helpers/httpHelper';
import { Alert, Autocomplete, TextField } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';

function AddUser({ user, setUser, getAllUsers, decoded }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState('');
	const [name, setName] = useState('');
	const [profileImage, setProfileImage] = useState('default_profile.png');
	const [isAdmin, setIsAdmin] = useState(false);
	const [bank, setBank] = useState([]);
	const [selectedBank, setSelectedBank] = useState(null);

	const [emailAlert, setEmailAlert] = useState(false);
	const [emailValidationAlert, setEmailValidationAlert] = useState(false);
	const [passwordAlert, setPasswordAlert] = useState(false);
	const [usernameAlert, setUsernameAlert] = useState(false);
	const [userTypeAlert, setUserTypeAlert] = useState(false);
	const [nameAlert, setNameAlert] = useState(false);

	useEffect(() => {
		if (user?._id) {
			setEmail(user?.email);
			// setPassword(user?.password);
			setUsername(user?.username);
			setName(user?.name);
			setProfileImage(user?.profileImage);
			setIsAdmin(user?.isAdmin);
			setSelectedBank(user?.userType);
		} else {
			setEmail('');
			setPassword('');
			setUsername('');
			setName('');
			setProfileImage('default_profile.png');
			setIsAdmin(false);
			setSelectedBank(null);
		}
	}, [user]);

	const createUser = async () => {
		try {
			const data = {
				email,
				password,
				username,
				name,
				profileImage,
				isAdmin,
				userType: selectedBank,
			};
			if (!selectedBank?._id || email === '' || password === '' || username === '' || name === '') {
				if (email === '') {
					setEmailAlert(true);
				}
				if (password === '') {
					setPasswordAlert(true);
				}
				if (username === '') {
					setUsernameAlert(true);
				}
				if (name === '') {
					setNameAlert(true);
				}
				if (!selectedBank?._id) {
					setUserTypeAlert(true);
				}
				return;
			}

			// email validation check 	 /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
				setEmailValidationAlert(true);
				return;
			}

			const response = await POST('user/register', data);
			setUser({});
			getAllUsers();
		} catch (err) {
			console.log(err);
		}
	};

	// ! alert close functions start here
	useEffect(() => {
		if (selectedBank) {
			setUserTypeAlert(false);
		}
		if (email) {
			setEmailAlert(false);
		}
		if (password) {
			setPasswordAlert(false);
		}
		if (username) {
			setUsernameAlert(false);
		}
		if (name) {
			setNameAlert(false);
		}
		if (email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
			setEmailValidationAlert(false);
		}
	}, [selectedBank, email, password, username, name]);

	const updateUser = async () => {
		try {
			const data = {
				email,
				password,
				username,
				name,
				profileImage,
				isAdmin,
				userType: selectedBank?._id,
			};
			const response = await PUT(`customer/protected/update?id=${user?._id}`, data);
			setUser({});
			getAllUsers();
		} catch (err) {
			console.log(err);
		}
	};

	//! Get All Bankes
	const getBank = async () => {
		try {
			const response = await GET('bank/get');
			response.data.sort((a, b) => {
				const nameA = a.name?.toUpperCase();
				const nameB = b.name?.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			const index = response.data.findIndex((bank) => bank.name === 'SYSTEM');
			if (index !== -1) {
				const [removed] = response.data.splice(index, 1);
				response.data.unshift(removed);
			}

			setBank(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getBank();
	}, []);

	//! Create User Confirm Alert
	const submit = (id) => {
		if (!selectedBank?._id || email === '' || password === '' || username === '' || name === '') {
			if (email === '') {
				setEmailAlert(true);
			}
			if (password === '') {
				setPasswordAlert(true);
			}
			if (username === '') {
				setUsernameAlert(true);
			}
			if (name === '') {
				setNameAlert(true);
			}
			if (!selectedBank?._id) {
				setUserTypeAlert(true);
			}
			return;
		}

		if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
			setEmailValidationAlert(true);
			return;
		}

		confirmAlert({
			title: 'Are you sure you want to Create this User?',
			message: 'It will be Create permanently.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={createUser}
				/>
			),
		});
	};

	//! Update User Confirm Alert
	const updateSubmit = (id) => {
		if (!selectedBank?._id || email === '' || username === '' || name === '') {
			if (email === '') {
				setEmailAlert(true);
			}
			if (username === '') {
				setUsernameAlert(true);
			}
			if (name === '') {
				setNameAlert(true);
			}
			if (!selectedBank?._id) {
				setUserTypeAlert(true);
			}
			return;
		}

		if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
			setEmailValidationAlert(true);
			return;
		}

		confirmAlert({
			title: 'Are you sure you want to Update this User?',
			message: 'It will be Update permanently and all the data will be updated.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={updateUser}
				/>
			),
		});
	};
	return (
		<>
			<div className="flex flex-col gap-6">
				{user?.email === decoded.email ? null : (
					<>
						<div className="flex bg-slate-200 p-2 rounded items-center">
							<label htmlFor="email" className="w-24 ml-2 text-sm font-medium text-black">
								Email
							</label>
							<input
								disabled={user?.email === decoded.email}
								required
								type="email"
								className="w-full p-2 rounded"
								id="email"
								placeholder="Enter email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						{emailAlert && <Alert severity="error">Please insert a email</Alert>}
						{emailValidationAlert && <Alert severity="error">Please insert a valid email</Alert>}
					</>
				)}
				<div className="flex bg-slate-200 p-2 rounded items-center">
					<label htmlFor="password" className="w-24 ml-2 text-sm font-medium text-black">
						Password
					</label>
					<input
						required
						type="password"
						className="w-full p-2 rounded"
						id="password"
						placeholder="Enter password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				{passwordAlert && <Alert severity="error">Please insert a password</Alert>}
				{user?.email === decoded.email ? null : (
					<>
						<div className="flex bg-slate-200 p-2 rounded items-center">
							<label htmlFor="username" className="w-24 ml-2 text-sm font-medium text-black">
								Username
							</label>
							<input
								required
								type="text"
								className="w-full p-2 rounded"
								id="username"
								placeholder="Enter username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
							/>
						</div>
						{usernameAlert && <Alert severity="error">Please insert a username</Alert>}

						<div className="flex bg-slate-200  rounded items-center">
							<Autocomplete
								className="w-full  rounded"
								placeholder="Select Bank"
								id="bank-select"
								options={bank}
								getOptionLabel={(option) => option.name}
								isOptionEqualToValue={(option, value) =>
									option._id === value._id || option.name === value.name
								}
								renderInput={(params) => (
									<TextField {...params} label="Select User Type" variant="outlined" />
								)}
								onChange={(event, value) => setSelectedBank(value)}
								value={selectedBank || null}
							/>
						</div>
						{userTypeAlert && <Alert severity="error">Please select a User Type</Alert>}

						<div className="flex bg-slate-200 p-2 rounded items-center">
							<label htmlFor="name" className="w-24 ml-2 text-sm font-medium text-black">
								Name
							</label>
							<input
								required
								type="text"
								className="w-full p-2 rounded"
								id="name"
								placeholder="Enter name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						{nameAlert && <Alert severity="error">Please insert a name</Alert>}

						<div className="flex bg-slate-200 p-2 rounded items-center">
							<label htmlFor="isAdmin" className="w-24 ml-2 text-sm font-medium text-black">
								Is Admin
							</label>
							<input
								type="checkbox"
								className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
								id="isAdmin"
								placeholder="Enter is admin"
								checked={isAdmin}
								onChange={(e) => setIsAdmin(!isAdmin)}
								disabled={user?.email === decoded.email}
							/>
						</div>
					</>
				)}
				{!user?._id ? (
					<button
						type="submit"
						className="btn btn-primary bg-green-300 rounded leading-10 hover:bg-green-400"
						onClick={() => submit()}
					>
						Create User
					</button>
				) : (
					<div className="w-full flex flex-col">
						<button
							type="submit"
							className="btn btn-primary bg-gray-300 rounded leading-10 hover:bg-gray-400"
							onClick={() => updateSubmit()}
						>
							Update User
						</button>
						<div
							className="text-center text-blue-700 cursor-pointer underline-offset-1"
							onClick={() => setUser({})}
						>
							Create new User
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default AddUser;
