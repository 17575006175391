import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../asset/image/logo.png';

function PDF({ data }) {
	const generatePDF = (data) => {
		const doc = new jsPDF({
			orientation: 'landscape',
			unit: 'mm',
			format: [420, 297], // set A3 paper size
		});
		const pageWidth = doc.internal.pageSize.width;

		// Header
		doc.setFontSize(20);
		doc.setTextColor(255, 0, 0);
		doc.setFont(undefined, 'bold');
		doc.text('Customer Profile', pageWidth / 2, 15, { align: 'center' });

		// Sub header
		doc.setTextColor(0, 0, 0);
		doc.setFontSize(14);
		doc.setFont(undefined, 'bold');
		doc.text('KHC Recovery', pageWidth / 2, 25, { align: 'center' });

		// Created Date
		doc.setFontSize(10);
		doc.setFont(undefined, 'normal');
		doc.text(
			`Created Date: ${new Date().toLocaleDateString('en-US', {
				weekday: 'long',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			})}`,
			20,
			25
		);

		// Logo
		const img = new Image();
		img.src = logo;
		doc.addImage(img, 'PNG', pageWidth - 30, 5, 20, 20);

		// Line break
		doc.line(5, 30, pageWidth - 5, 30);

		// Customer Details Section
		doc.setTextColor(0, 0, 0);
		doc.setFontSize(14);
		doc.setFont(undefined, 'bold');
		doc.text('Personal Information', 20, 40);

		// Customer Details Section
		doc.setFontSize(12);
		doc.setFont(undefined, 'normal');
		doc.text(`Card Holder Name: ${data?.customer.name ? data?.customer.name : 'N/A'}`, 20, 50);
		doc.text(`NIC: ${data?.customer.nic ? data?.customer.nic : 'N/A'}`, 20, 60);
		doc.text(`Address: ${data?.customer.address ? data?.customer.address : 'N/A'}`, 20, 70);
		doc.text(`Bank: ${data?.customer.bank.name ? data?.customer.bank.name : 'N/A'}`, 20, 80);
		doc.text(`Telephone Number: ${data?.customer.tel ? data?.customer.tel : 'N/A'}`, 20, 90);
		doc.text(
			`LOD Date: ${
				data?.customer.lodDate
					? new Date(data?.customer.lodDate).toLocaleDateString('en-US', {
							weekday: 'long',
							year: 'numeric',
							month: 'short',
							day: 'numeric',
					  })
					: 'N/A'
			}`,
			20,
			100
		);
		doc.text(
			`Visit Date: ${
				data?.customer.visitDate
					? new Date(data?.customer.visitDate).toLocaleDateString('en-US', {
							weekday: 'long',
							year: 'numeric',
							month: 'short',
							day: 'numeric',
					  })
					: 'N/A'
			}`,
			20,
			110
		);
		doc.text(`Settlement: ${data?.customer.settlement ? data?.customer.settlement : 'N/A'}`, 20, 120);

		// Relative Details Section
		doc.setFontSize(14);
		doc.setFont(undefined, 'bold');
		doc.text('Relative Details', pageWidth / 2, 40);

		doc.setFontSize(12);
		doc.setFont(undefined, 'normal');
		doc.text(
			`Relative Name: ${data?.customer.relativeName ? data?.customer.relativeName : 'N/A'}`,
			pageWidth / 2,
			50
		);
		doc.text(
			`Relative Telephone Number: ${data?.customer.relativeTel ? data?.customer.relativeTel : 'N/A'}`,
			pageWidth / 2,
			60
		);
		doc.text(
			`Relationship: ${data?.customer.relativeRelation ? data?.customer.relativeRelation : 'N/A'}`,
			pageWidth / 2,
			70
		);

		// Payment History Section
		doc.setFontSize(14);
		doc.setFont(undefined, 'bold');
		doc.text('Payment History', 20, 135);

		doc.setFont(undefined, 'normal');
		// Table headers
		const headers = [
			[
				'Recovery Officer',
				'Bill Cycle Code',
				'Month Due',
				'Credit Limit',
				'Status Date',
				'Outstanding Amount',
				'Extra Amount',
				'LAST PAYMENT DATE',
				'Last Payment Amount',
				'Payment Details',
				'Recovery Office Comments',
				'Other Comments',
			],
		];
		const historyData = data?.history.map((item) => [
			item.recoveryOfficer,
			item.billCycleCode,
			item.monthDue,
			item.creditLimit,
			new Date(item.statusDate).toLocaleDateString(),
			item.outstandingAmount,
			item.extraAmount,
			new Date(item.lastPaymentDate).toLocaleDateString(),
			item.lastPaymentAmount,
			item.paymentDetails,
			item.recoveryOfficeComments,
			item.otherComments,
		]);
		const tableData = headers.concat(historyData);

		// Table styling
		doc.autoTable({
			startY: 140,
			headStyles: {
				fillColor: [100, 100, 100],
			},
			bodyStyles: {
				textColor: [0, 0, 0],
			},
			head: tableData.slice(0, 1),
			body: tableData.slice(1),
		});

		// Footer
		doc.setFontSize(10);
		const pageCount = doc.internal.getNumberOfPages();
		for (let i = 1; i <= pageCount; i++) {
			doc.setPage(i);
			doc.text(`Page ${i} of ${pageCount}`, pageWidth - 20, doc.internal.pageSize.height - 10);
		}

		// Save the document with file name and current time and date
		const now = new Date();
		const currentDate = now.toLocaleDateString();
		const currentTime = now.toLocaleTimeString();
		const fileName = `KHC Recovery - ${
			data?.customer.name ? data?.customer.name : 'Unknown'
		} - ${currentDate} ${currentTime}.pdf`;
		doc.save(fileName);
	};
	return (
		<>
			<button
				className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 cursor-pointer"
				onClick={() => generatePDF(data)}
			>
				PDF
			</button>
		</>
	);
}

export default PDF;
