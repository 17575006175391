import React, { useEffect, useState } from 'react';
import { DELETE, PUT } from '../../helpers/httpHelper';
import { Alert } from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';
import PDF from '../Document/PDF';

function UserTable({ user, data, decoded }) {
	const [isEdit, setIsEdit] = useState(false);

	//! Update
	const [bank, setBank] = useState(user?.bank);
	const [name, setName] = useState(user?.name);
	const [address, setAddress] = useState(user?.address);
	const [tel, setTel] = useState(user?.tel);
	const [lodDate, setLodDate] = useState(user?.lodDate);
	const [visitDate, setVisitDate] = useState(user?.visitDate);
	const [visitDetails, setVisitDetails] = useState(user?.visitDetails);
	const [settlement, setSettlement] = useState(user?.settlement);
	const [relativeName, setRelativeName] = useState(user?.relativeName);
	const [relativeTel, setRelativeTel] = useState(user?.relativeTel);
	const [relativeRelation, setRelativeRelation] = useState(user?.relativeRelation);

	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const CustomerUpdate = async (e) => {
		try {
			if (decoded?.userType?.name === 'SYSTEM') {
				const data = {
					bank,
					name,
					address,
					tel,
					lodDate,
					visitDate,
					visitDetails,
					settlement,
					relativeName,
					relativeTel,
					relativeRelation,
				};
				const response = await PUT(`customer/update?id=${user?._id}`, data);
				setAlertMessage(response.message);
				setIsEdit(false);
				setAlert(true);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Update this record?',
			message: 'It will be Update permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={CustomerUpdate}
				/>
			),
		});
	};

	const deleteData = async (id) => {
		try {
			if (decoded?.userType?.name === 'SYSTEM') {
				const response = await DELETE(`customer/delete?id=${id}`);
				window.location.href = '/';
			}
		} catch (err) {
			console.log(err);
		}
	};

	const deleteConfirmation = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Delete this record?',
			message: 'It will be Delete permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={deleteData}
				/>
			),
		});
	};

	useEffect(() => {
		if (alert) {
			setTimeout(() => {
				setAlert(false);
			}, 3000);
		}
	}, [alert]);

	return (
		<>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6 shadow-xl border rounded p-3">
				<div className="md:col-span-2">
					<p className="mt-1 text-xl font-bold text-gray-600 text-center">Customer Details</p>
					{alert && <Alert severity="success">{alertMessage}</Alert>}
				</div>

				<div className="grid grid-cols-1 gap-3">
					<div className="px-4 sm:px-0">
						<h3 className="text-base font-semibold  text-gray-900">Personal Information</h3>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Card Holder Name</label>
						<input
							type="text"
							defaultValue={name ? name : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setName(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Addresss</label>
						<input
							type="text"
							defaultValue={address ? address : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setAddress(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Telephone Number</label>
						<input
							type="text"
							defaultValue={tel ? tel : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setTel(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">LOD Date</label>
						<input
							type="date"
							value={lodDate ? lodDate.slice(0, 10) : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setLodDate(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Visit Date</label>
						<input
							type="date"
							value={visitDate ? visitDate.slice(0, 10) : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setVisitDate(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Visit details</label>
						<input
							type="text"
							defaultValue={visitDetails ? visitDetails : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setVisitDetails(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">
							Settlement Plane Details
						</label>
						<input
							type="text"
							defaultValue={settlement ? settlement : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setSettlement(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
				</div>
				<div className="grid grid-cols-1 gap-3 self-start">
					<div className="px-4 sm:px-0">
						<h3 className="text-base font-semibold  text-gray-900">Relative Details</h3>
					</div>

					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Relative Name</label>
						<input
							type="text"
							defaultValue={relativeName ? relativeName : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setRelativeName(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Telephone Number</label>
						<input
							type="text"
							defaultValue={relativeTel ? relativeTel : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setRelativeTel(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Relationship</label>
						<input
							type="text"
							defaultValue={relativeRelation ? relativeRelation : 'N/A'}
							disabled={!isEdit}
							onChange={(e) => setRelativeRelation(e.target.value)}
							className="disabled:bg-gray-200  pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="h-0 md:h-40"></div>

					<div className=" flex justify-center gap-3 md:justify-end">
						{!isEdit ? (
							<>
								<PDF data={data} />
								{decoded?.userType?.name === 'SYSTEM' && (
									<>
										<button
											onClick={() => {
												deleteConfirmation(user?._id);
											}}
											className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
										>
											Delete
										</button>
										<div
											onClick={() => {
												setIsEdit(true);
											}}
											className="inline-flex justify-center rounded-md bg-orange-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 cursor-pointer"
										>
											Edit
										</div>
									</>
								)}
							</>
						) : (
							<>
								<div
									onClick={() => {
										setIsEdit(false);
									}}
									className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 cursor-pointer"
								>
									Cancel
								</div>
								<div
									className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
									onClick={() => {
										submit();
									}}
								>
									Save
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default UserTable;
