import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from '../../asset/image/logo.png';
import default_profile from '../../asset/image/default_profile.png';
import { Link } from 'react-router-dom';
import routes from '../../configs/RouteConfig';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar({ decoded }) {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar position="relative">
			<Container maxWidth="xl">
				<Toolbar disableGutters className="flex justify-between">
					<div className="flex items-center">
						<a className="flex items-center " href="/">
							<img src={logo} className="w-14 hidden sm:block mr-4" alt="logo" />
							<div className="hidden sm:block font-bold text-xl">KHC Recoveries</div>
						</a>
						{decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && (
							<Box className="block sm:hidden ">
								<IconButton size="large" onClick={handleOpenNavMenu} color="inherit">
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									className="block sm:hidden"
								>
									<MenuItem onClick={handleCloseNavMenu}>
										{routes.map((route, index1) => (
											<Link key={index1} to={route.path} className="mx-4">
												<Typography onClick={handleCloseNavMenu}>{route.name}</Typography>
											</Link>
										))}
									</MenuItem>
								</Menu>
							</Box>
						)}
						<a className="flex items-center " href="/">
							<img src={logo} className="w-10 sm:hidden block mr-4" alt="logo" />
							<div className="sm:hidden block">KHC Recoveries</div>
						</a>
						<div className="sm:block hidden">
							<Box className="flex-grow flex items-center">
								{decoded?.isAdmin &&
									decoded?.userType?.name === 'SYSTEM' &&
									routes.map((route, index2) => (
										<Link key={index2} to={route.path} className="mx-4">
											<Button
												onClick={handleCloseNavMenu}
												sx={{ my: 2, color: 'white', display: 'block' }}
											>
												{route.name}
											</Button>
										</Link>
									))}
							</Box>
						</div>
					</div>

					<Box sx={{ flexGrow: 0 }} className="">
						<div className="flex items-center gap-1 sm:gap-10">
							<div className="hidden sm:block text-md" variant="h6">
								{decoded?.userType?.name}
								{decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && ' - Admin'}
								{!decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && ' - User'}
							</div>
							<div className="block sm:hidden text-sm">
								{decoded?.userType?.name}
								{decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && ' - Admin'}
								{!decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && ' - User'}
							</div>
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<Avatar alt="Remy Sharp" src={default_profile} />
								</IconButton>
							</Tooltip>
						</div>
						<Menu
							sx={{ mt: '45px' }}
							id="menu-appbar"
							anchorEl={anchorElUser}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={Boolean(anchorElUser)}
							onClose={handleCloseUserMenu}
						>
							<MenuItem key="Profile" onClick={handleCloseUserMenu}>
								<Link to="/profile">
									<Typography textAlign="center">Profile</Typography>
								</Link>
							</MenuItem>
							<MenuItem
								key="Logout"
								onClick={() => {
									localStorage.removeItem('token');
									localStorage.clear();
									window.location.href = '/';
								}}
							>
								<Typography textAlign="center">Logout</Typography>
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default Navbar;
