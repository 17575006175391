import React, { useEffect, useState } from 'react';
import PopUp from '../Util/PopUp';
import BulkUpload from '../Document/BulkUpload';
import { GET, POST } from '../../helpers/httpHelper';
import { Alert, Autocomplete, TextField } from '@mui/material';
import UserTable from '../Tables/UserTable';
import HistoryTable from '../Tables/HistoryTable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Home({ userType, decoded }) {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({});
	const [bank, setBank] = useState([]);
	const [selectedBank, setSelectedBank] = useState(null);
	const [nic, setNic] = useState('');

	//! Alert
	const [bankAlert, setBankAlert] = useState(false);
	const [nicAlert, setNicAlert] = useState(false);

	//! user
	const [user, setUser] = useState({});
	const [history, setHistory] = useState([]);
	const [isShow, setIsShow] = useState(false);

	//! Loading
	const [isLoading, setIsLoading] = useState(false);

	//! Get All Bankes
	const getBank = async () => {
		try {
			const response = await GET('bank/get');
			response.data.sort((a, b) => {
				const nameA = a?.name?.toUpperCase();
				const nameB = b?.name?.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			setBank(response.data);
			// remove  SYSTEM  from the list
			const index = response.data.findIndex((x) => x?.name === 'SYSTEM');
			response.data.splice(index, 1);
		} catch (err) {
			console.log(err);
		}
	};

	const updateDataSet = async () => {
		try {
			setIsLoading(true);
			if (!selectedBank || nic === '') {
				if (!selectedBank) setBankAlert(true);
				if (!nic) setNicAlert(true);
				setIsLoading(false);
				return;
			}

			const response = await POST('customer/fetch', {
				nic,
				bank: selectedBank?._id,
			});
			setIsShow(true);
			setUser(response.data.customer);
			setHistory(response.data.history);
			setData(response.data);

			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getBank();
	}, []);

	useEffect(() => {
		if (selectedBank) {
			setBankAlert(false);
			setIsShow(false);
		}
		if (nic) {
			setNicAlert(false);
			setIsShow(false);
		}
	}, [selectedBank, nic]);

	useEffect(() => {
		if (userType?.name !== 'SYSTEM') {
			setSelectedBank(userType);
		}
	}, [userType]);

	const uploadSuccess = () =>
		toast.success('Upload Successful, Waiting', {
			position: 'top-right',
			autoClose: 1950,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	const uploadFail = () =>
		toast.error('Upload Failed', {
			position: 'top-right',
			autoClose: 1950,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});

	return (
		<div>
			<p className="text-xl font-bold text-gray-600 text-center mt-5">Customer Handling</p>
			<div className="flex flex-col gap-6 p-6 sm:p-12">
				{decoded?.userType?.name === 'SYSTEM' && (
					<PopUp
						open={open}
						setOpen={setOpen}
						popUpContent={
							<BulkUpload setOpen={setOpen} uploadSuccess={uploadSuccess} uploadFail={uploadFail} />
						}
					/>
				)}
				{/* <PDFCreator data={data} /> */}
				<div className="flex gap-6 flex-col sm:flex-row ">
					{userType?.name === 'SYSTEM' && (
						<div className="flex flex-col w-full">
							<Autocomplete
								className="w-full h-14"
								id="bank-select"
								options={bank}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => (
									<TextField {...params} label="Select Bank" variant="outlined" />
								)}
								onChange={(event, value) => setSelectedBank(value)}
								value={selectedBank || null}
							/>
							{bankAlert && <Alert severity="error">Please select a bank</Alert>}
						</div>
					)}
					{userType?.name !== 'SYSTEM' && (
						<div className="flex flex-col w-full ">
							<input
								type="text"
								placeholder="Bank"
								disabled={userType !== 'SYSTEM'}
								value={userType?.name}
								className="bg-gray-300 rounded p-2 w-full  h-14 hover:bg-slate-200 disabled:bg-gray-300"
								onChange={(e) => setSelectedBank(e.target.value)}
							/>
						</div>
					)}
					<div className="flex flex-col w-full ">
						<input
							type="text"
							placeholder="Enter NIC"
							className="bg-gray-300 rounded p-2 w-full  h-14 hover:bg-slate-200"
							onChange={(e) => setNic(e.target.value)}
						/>

						{nicAlert && <Alert severity="error">Please enter a NIC</Alert>}
					</div>
					<button
						className=" h-14 rounded-md bg-red-600 py-2 px-10 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						autoFocus
						onClick={updateDataSet}
					>
						Search
					</button>
				</div>
				{!isLoading ? (
					<>
						{isShow &&
							(user ? (
								<>
									<UserTable user={user} data={data} decoded={decoded} />
									<HistoryTable history={history} decoded={decoded} updateDataSet={updateDataSet} />
								</>
							) : (
								<>
									<div className="flex flex-col gap-6 p-12">
										<Alert severity="error">No data found</Alert>
									</div>
								</>
							))}
					</>
				) : (
					<div className="flex items-center justify-center mt-10">
						<div
							className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
							role="status"
						>
							<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
								Loading...
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Home;
