import React, { useEffect, useState } from 'react';
import { DELETE, GET, PUT } from '../../helpers/httpHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';

function AllUsers({ users, decoded, setUser, getAllUsers }) {
	const isUserAdminUpdate = async (id, isAdmin, user) => {
		try {
			const data = {
				email: user?.email,
				password: '',
				username: user?.username,
				name: user?.name,
				isAdmin: isAdmin,
				userType: user?.userType,
			};
			const response = await PUT(`customer/protected/update?id=${id}`, data);
			getAllUsers();
		} catch (err) {
			console.log(err);
		}
	};

	const deleteUser = async (id) => {
		try {
			const response = await DELETE(`customer/protected/delete?id=${id}`);
			getAllUsers();
			setOpen(false);
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Update this record?',
			message: 'It will be Update permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={deleteUser}
				/>
			),
		});
	};
	return (
		<div>
			<div className="overflow-y-auto">
				<table className="table-auto border-collapse border border-slate-500">
					<thead>
						<tr>
							<th className="px-4 py-2 border border-slate-300">Name</th>
							<th className="px-4 py-2 border border-slate-300">Email</th>
							<th className="px-4 py-2 border border-slate-300">Username</th>
							<th className="px-4 py-2 border border-slate-300">Is Admin</th>
							<th className="px-4 py-2 border border-slate-300">User Type</th>
							<th className="px-4 py-2 border border-slate-300">Admin Role</th>
							<th className="px-4 py-2 border border-slate-300">Edit</th>
							<th className="px-4 py-2 border border-slate-300">Delete</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user?._id}>
								<td className="px-4 py-2 border border-slate-300">
									{user?.name ? user?.name : 'No Name'}
								</td>
								<td className="px-4 py-2 border border-slate-300">
									{user?.email ? user?.email : 'No Email'}
								</td>
								<td className="px-4 py-2 border border-slate-300">
									{user?.username ? user?.username : 'No Username'}
								</td>
								<td className="px-4 py-2 border border-slate-300">{user?.isAdmin ? 'Yes' : 'No'}</td>
								<td className="px-4 py-2 border border-slate-300">
									{user?.userType?.name ? user?.userType?.name : 'No Type'}
								</td>
								<td className="px-4 py-2 border border-slate-300">
									{user?.isAdmin ? (
										<button
											className=" disabled:bg-red-200 disabled:cursor-default inline-flex justify-center rounded-md bg-red-600 py-2  w-full px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
											onClick={() => {
												isUserAdminUpdate(user?._id, false, user);
											}}
											disabled={user?.email === decoded.email}
										>
											Revoke
										</button>
									) : (
										<button
											className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 w-full cursor-pointer"
											onClick={() => {
												isUserAdminUpdate(user?._id, true, user);
											}}
											disabled={user?.email === decoded.email}
										>
											Grant
										</button>
									)}
								</td>
								<td className="px-4 py-2 border border-slate-300 text-center">
									<button
										onClick={() => {
											setUser(user);
										}}
										className="disabled:bg-indigo-200 disabled:cursor-default inline-flex justify-center rounded-md bg-indigo-600 py-2 px-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 cursor-pointer"
									>
										<DriveFileRenameOutlineIcon />
									</button>
								</td>
								<td className="px-4 py-2 border border-slate-300  text-center">
									<button
										className="disabled:bg-red-200 disabled:cursor-default inline-flex justify-center rounded-md bg-red-600 py-2 px-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
										disabled={user?.email === decoded.email || user?.username === 'admin'}
										onClick={() => {
											submit(user._id);
										}}
									>
										<DeleteIcon />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default AllUsers;
