const routes = [
	{
		path: '/users',
		name: 'Users',
	},
	{
		path: '/banks',
		name: 'Banks',
	},
];

export default routes;
