import React, { useState } from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Card, DialogContent } from '@mui/material';
import { DELETE } from '../../helpers/httpHelper';

function AllBanks({ banks, setBank, getAllBanks }) {
	const [open, setOpen] = useState(false);
	const [bankId, setBankId] = useState('');

	const index = banks.findIndex((bank) => bank.name === 'SYSTEM');
	if (index !== -1) {
		const [removed] = banks.splice(index, 1);
		banks.unshift(removed);
	}

	const deleteBank = async (id) => {
		try {
			const response = await DELETE(`bank/delete?id=${id}`);
			getAllBanks();
			setOpen(false);
		} catch (err) {
			console.log(err);
		}
	};

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to delete this record?',
			message: 'It will be deleted permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<Card className="bg-slate-600">
					<DialogContent className="flex flex-col gap-6 bg-slate-800 text-white">
						<div className="flex justify-between">
							<div className="pr-9 self-center">{title}</div>
							<IconButton onClick={onClose} className="float-right justify-end w-10 items-center">
								<CloseIcon className="hover:text-white hover:bg-inherit" />
							</IconButton>
						</div>
						<div>{message}</div>
						<div className="flex gap-3 justify-end">
							<button
								onClick={onClose}
								className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right p-2 "
							>
								No
							</button>
							<button
								onClick={() => {
									deleteBank(id);
									onClose();
								}}
								className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right p-2 "
							>
								Yes
							</button>
						</div>
					</DialogContent>
				</Card>
			),
		});
	};

	return (
		<div>
			<div className="overflow-y-auto">
				<table className="table-auto border-collapse border border-slate-500">
					<thead>
						<tr>
							<th className="px-4 py-2 border border-slate-300">Name</th>
							<th className="px-4 py-2 border border-slate-300">Edit</th>
							<th className="px-4 py-2 border border-slate-300">Delete</th>
						</tr>
					</thead>
					<tbody>
						{banks.map((bank, index) => (
							<tr key={index}>
								<td className="px-4 py-2 border border-slate-300">
									{bank?.name ? bank?.name : 'No Name'}
								</td>

								<td className="px-4 py-2 border border-slate-300 text-center">
									<button
										onClick={() => {
											setBank(bank);
										}}
										disabled={bank?.name === 'SYSTEM'}
										className="disabled:bg-indigo-300 inline-flex justify-center rounded-md bg-indigo-600 py-2 px-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 cursor-pointer"
									>
										<DriveFileRenameOutlineIcon />
									</button>
								</td>
								<td className="px-4 py-2 border border-slate-300  text-center">
									<button
										disabled={bank?.name === 'SYSTEM'}
										onClick={() => {
											submit(bank?._id);
										}}
										className="disabled:bg-red-300 inline-flex justify-center rounded-md bg-red-600 py-2 px-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
									>
										<DeleteIcon type="button" />
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				{/* <DeletePoUp open={open} setOpen={setOpen} id={bankId} getAllBanks={getAllBanks} /> */}
			</div>
		</div>
	);
}

export default AllBanks;
