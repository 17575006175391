import React, { useEffect, useState } from 'react';
import AddBank from './AddBank';
import { GET } from '../../helpers/httpHelper';
import AllBanks from './AllBanks';

function Banks({ decoded }) {
	const [bank, setBank] = useState({});
	const [banks, setBanks] = useState([]);

	//! Get All Bankes
	const getAllBanks = async () => {
		try {
			const response = await GET('bank/get');
			response.data.sort((a, b) => {
				const nameA = a.name?.toUpperCase();
				const nameB = b.name?.toUpperCase();
				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			});
			setBanks(response.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllBanks();
	}, []);

	return (
		<div>
			<p className="text-xl font-bold text-gray-600 text-center mt-5">Bank Handling</p>
			<div className="flex justify-center flex-col sm:flex-row">
				<div className="p-9">
					<AddBank bank={bank} setBank={setBank} getAllBanks={getAllBanks} decoded={decoded} />
				</div>
				<div className="p-9 text-center self-center sm:self-start">
					<AllBanks banks={banks} decoded={decoded} setBank={setBank} getAllBanks={getAllBanks} />
				</div>
			</div>
		</div>
	);
}

export default Banks;
