import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import ConfirmationBox from '../Util/ConfirmationBox';
import { GET, PUT } from '../../helpers/httpHelper';

function Profile() {
	const [user, setUser] = useState({});
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isAdmin, setIsAdmin] = useState('');
	const [profileImage, setProfileImage] = useState('');
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [userType, setUserType] = useState('');
	const [isEdit, setIsEdit] = useState(false);

	const getAllUsers = async () => {
		try {
			const response = await GET('customer/protected/getOne');
			setUser(response.data);
			setName(response.data.name);
			setEmail(response.data.email);
			setIsAdmin(response.data.isAdmin);
			setProfileImage(response.data.profileImage);
			setUsername(response.data.username);
			setUserType(response.data.userType);
		} catch (err) {
			console.log(err);
		}
	};

	const userUpdate = async (id) => {
		try {
			const data = {
				email: email,
				password: password,
				username: username,
				name: name,
				isAdmin: isAdmin,
				userType: userType,
			};

			const response = await PUT(`customer/protected/update?id=${id}`, data);
			getAllUsers();
			setIsEdit(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllUsers();
	}, []);

	const submit = (id) => {
		confirmAlert({
			title: 'Are you sure you want to Update this record?',
			message: 'It will be Update permanently and cannot be recovered.',
			closeOnEscape: true,
			closeOnClickOutside: false,
			customUI: ({ title, message, onClose }) => (
				<ConfirmationBox
					title={title}
					message={message}
					onClose={onClose}
					id={id}
					onClickFunction={userUpdate}
				/>
			),
		});
	};

	return (
		<div className="p-12 flex justify-center">
			<div className="flex flex-col gap-6 shadow-xl border rounded p-3  w-full lg:w-1/2">
				<div className="">
					<p className="mt-1 text-xl font-bold text-gray-600 text-center">Profile Details</p>
				</div>

				<div className="grid grid-cols-1 gap-3">
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Name</label>
						<input
							defaultValue={name}
							disabled={!isEdit}
							onChange={(e) => setName(e.target.value)}
							className=" pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">Email</label>
						<input
							defaultValue={email}
							disabled={!isEdit}
							onChange={(e) => setEmail(e.target.value)}
							className=" pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>
					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">username</label>
						<input
							defaultValue={username}
							disabled={!isEdit}
							onChange={(e) => setUsername(e.target.value)}
							className=" pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm "
						/>
					</div>

					<div className="flex flex-col sm:flex-row whitespace-nowrap sm:items-center gap-3">
						<label className="block text-sm font-medium  text-gray-900 md:w-52">user Type</label>
						<div className=" pl-3 mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm ">
							{userType?.name ? userType?.name : 'N/A'}
						</div>
					</div>
					<div className=" flex justify-center gap-3 md:justify-end h-10 ">
						{!isEdit ? (
							<div
								onClick={() => {
									setIsEdit(true);
								}}
								className="inline-flex justify-center rounded-md bg-orange-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 cursor-pointer"
							>
								Edit
							</div>
						) : (
							<>
								<div
									onClick={() => {
										setIsEdit(false);
									}}
									className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 cursor-pointer"
								>
									Cancel
								</div>
								<div
									className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 cursor-pointer"
									onClick={() => {
										submit(user?._id);
									}}
								>
									Save
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Profile;
