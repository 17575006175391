import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom';
import Login from './components/Login/Login';
import Navbar from './components/Util/Navbar';
import Home from './components/Home/Home';
import Users from './components/Users/Users';
import jwt_decode from 'jwt-decode';
import Banks from './components/Bank/Banks';
import Profile from './components/Profile/Profile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	if (localStorage.getItem('token')) {
		var token = localStorage.getItem('token');
		var decoded = jwt_decode(token);
	}
	return (
		<HashRouter>
			{localStorage.getItem('token') && <Navbar decoded={decoded} />}
			<ToastContainer />
			<Routes>
				{localStorage.getItem('token') === null && <Route path="/" element={<Login />} />}
				{localStorage.getItem('token') && (
					<>
						<Route path="/" element={<Home userType={decoded.userType} decoded={decoded} />} />

						{decoded?.isAdmin && decoded?.userType?.name === 'SYSTEM' && (
							<>
								<Route path="/users" element={<Users decoded={decoded} />} />
								<Route path="/banks" element={<Banks decoded={decoded} />} />
							</>
						)}
						<Route path="/profile" element={<Profile email={decoded?.email} />} />
					</>
				)}
			</Routes>
		</HashRouter>
	);
}

export default App;
