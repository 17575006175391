import { Card, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ConfirmationBox({ title, message, onClose, id = '', onClickFunction }) {
	return (
		<>
			<Card className="bg-slate-600">
				<DialogContent className="flex flex-col gap-6 bg-slate-800 text-white">
					<div className="flex justify-between">
						<div className="pr-9 self-center">{title}</div>
						<IconButton onClick={onClose} className="float-right justify-end w-10 items-center">
							<CloseIcon className="hover:text-white hover:bg-inherit" />
						</IconButton>
					</div>
					<div>{message}</div>
					<div className="flex gap-3 justify-end">
						<button
							onClick={onClose}
							className="inline-flex justify-center rounded-md bg-green-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right p-2 "
						>
							No
						</button>
						<button
							onClick={() => {
								if (id !== '') onClickFunction(id);
								else onClickFunction();
								onClose();
							}}
							className="inline-flex justify-center rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right p-2 "
						>
							Yes
						</button>
					</div>
				</DialogContent>
			</Card>
		</>
	);
}

export default ConfirmationBox;
