import React, { useEffect, useState } from 'react';
import AddUser from './AddUser';
import AllUsers from './AllUsers';
import { GET } from '../../helpers/httpHelper';

function Users({ decoded }) {
	const [user, setUser] = useState({});

	const [users, setUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getAllUsers = async () => {
		try {
			setIsLoading(true);
			const response = await GET('customer/protected/get');

			if (decoded?.email === 'khcrecovery23@gmail.com') {
				setUsers(response.data);
			} else {
				const filteredUsers = response?.data.filter((user) => user.email !== 'khcrecovery23@gmail.com');
				setUsers(filteredUsers);
			}
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllUsers();
	}, []);

	return (
		<div>
			<p className="text-xl font-bold text-gray-600 text-center mt-5">Users Handling</p>
			<div className="flex justify-center flex-col lg:flex-row">
				<div className="p-9">
					<AddUser user={user} setUser={setUser} getAllUsers={getAllUsers} decoded={decoded} />
				</div>
				{!isLoading && users.length > 0 ? (
					<div className="p-9">
						<AllUsers users={users} decoded={decoded} setUser={setUser} getAllUsers={getAllUsers} />
					</div>
				) : (
					<div className="p-9 w-full h-96 flex justify-center">
						<div className="flex flex-col items-center justify-center">
							<div
								className="inline-block h-10 w-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
								role="status"
							></div>
							<span className=" text-black overflow-hidden ">Loading...</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Users;
